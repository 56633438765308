/*
 * Private Alex style
 * ************************************************************************************************ */

.content {
  margin-top: 15px; }

// .form-group:last-child,
// .panel:last-child
//   margin-bottom: 10px

.breadcrumbs {
  margin: 0 -15px; }
.breadcrumb {
  margin: -35px 0 0 0;
  background: none;
  text-align: right;
  font-weight: bold;
  text-transform: uppercase;
  padding: 8px 0; }

/*
 * Table
 * ************************************************************************************************ */
.form-control,
.input-group-addon {
  border-radius: 0;
  height: 36px;
  border: 1px solid #e0e0e0;
  font-size: 16px;
  color: #282828; }

#views-exposed-form-freelance-news-page #edit-field-freelance-state-wrapper {
  display: none; }

.form-item-created-date label,
.form-item-created-1-date label {
  display: none; }

span.team_member {
  font-size: 10px;
  font-weight: 600;
  color: #8e8e8e; }

.completed_check {
  margin-top: 20px; }

.dn_upl_block legend.panel-heading {
  text-transform: uppercase;
  padding-left: 0;
  font-weight: bold;
  width: 195px;
  float: left; }


.dn_upl_block legend.panel-heading .panel-title {
  font-size: 14px!important; }


.dn_upl_block fieldset.panel.panel-default {
  border: none;
  padding: 0;
  margin: 0; }


.dn_upl_block fieldset.panel.panel-default .panel-body {
  padding: 15px 0; }

#news-form .form-group {
  margin-bottom: 0px!important; }

#news-form .row {
  margin-bottom: 15px; }

.autoDelDate {
  margin-top: 10px; }

.form-item-field-new-media-type-und i {
  color: #a0a0a0; }


#news-form-all-bundle .form-group {
  margin-bottom: 0; }


.formBorder {
  border: 5px solid;
  padding: 5px; }

.form-control, .input-group-addon {
  margin-bottom: 0px !important; }


.keywords .row {
  margin: 0 -10px; }

.main-page {
  text-align: center; }

.replies {
  max-height: 800px;
  overflow-y: scroll; }

.replies-form {
  margin-top: 10px; }

.header_title {
  text-align: left; }

#edit-payment-method,
#edit-payment-method:after,
.add-payment .form-type-select .input-wrap:after {
  display: none; }
